export const proVideoData = {
  title: '产品介绍',
  source: '//static.textin.com/videos/web/studio.mp4',
};

export const proPlatformSceneData = {
  title: '平台场景',
  sceneList: [
    {
      name: '统一入口',
      desc: '支持除合合之外第三方提供的文字识别、语音识别、图像识别、人脸识别、活体识别等AI能力接入，并将不同接口及数据格式统一转化',
      image: '/images/studioPro/scene_input.png',
    },
    {
      name: '数据生成',
      desc: '当训练数据不足时，可以生成海量标注好的训练数据用于模型训练',
      image: '/images/studioPro/scene_data.png',
    },
    {
      name: '模型训练',
      desc: '支持一键自动训练，对训练过程进行监控，并可直接进行测试，查看模型识别率的变化',
      image: '/images/studioPro/scene_model.png',
    },
    {
      name: '模型下载与部署',
      desc: '产出的模型可以下载部署包或直接部署到线上服务器当中',
      image: '/images/studioPro/scene_download.png',
    },
  ],
};

export const proAdvanceData = {
  title: '产品优势',
  backgroundImage: '/images/scene/pic_adv_bg.png',
  advanceList: [
    {
      icon: '/images/studioPro/icon_pro_adv_01.png',
      name: '模型可视化',
      desc: '全可视化开发，支持模型创建、模型训练、模型测试于一体',
    },
    {
      icon: '/images/studioPro/icon_pro_adv_02.png',
      name: '训练识别引擎',
      desc: '业内最准确的预训练文字识别引擎，训练基础扎实',
    },
    {
      icon: '/images/studioPro/icon_pro_adv_03.png',
      name: '数据训练',
      desc: '数据标注简单易用，训练数据不足可大量生成训练数据',
    },
    {
      icon: '/images/studioPro/icon_pro_adv_04.png',
      name: '管理模型',
      desc: '所有任务集中管理，操作简单，无需技术背景也可完成模型开发',
    },
  ],
};

export const proSceneData = {
  title: '应用场景',
  sceneList: [
    {
      image: '/images/studioPro/pic_pro_scene_01@2x.png',
      name: '银行票证信息录入',
      desc: '银行票证种类多，人力成本投入巨大。在TextIn Studio可视化界面框选和点击即可创建模型并训练测试，解决定制化识别开发门槛高、周期长的问题',
    },
    {
      image: '/images/studioPro/pic_pro_scene_02@2x.png',
      name: '保单批量文字识别',
      desc: 'TextIn Studio可对各类保单一键训练并测试，当样本量不足时还可自动生成海量标注好的训练数据用于模型训练，提高文件批量定制化识别的精准度',
    },
    {
      image: '/images/studioPro/pic_pro_scene_03@2x.png',
      name: '证券报表配置识别',
      desc: 'TextIn Studio通过自学习数据样本深度强化学习，在具体业务场景下快速响应企业业务变化需求，对识别报表的细微变化无需进行算法调整',
    },
  ],
};

export const proPartnerList = {
  image: '/images/studioPro/pic_customer_pro_bg@2x.png',
  partnerList: [
    {
      logo: '/images/studioPro/partner_chongqing.png',
      companyInfo:
        '重庆农村商业银行股份有限公司使用合合信息TextIn Studio，提供对通用证照包括身份证、借记卡、社保卡、营业执照、开户许可证、行驶证、票据的识别服务，以及非标准化文档的配置训练，为文档电子化提供服务能力',
      companyName: '—— 重庆农村商业银行股份有限公司',
    },
    {
      logo: '/images/studioPro/partner_changsha.png',
      companyInfo:
        '长沙银行股份有限公司采用TextIn Studio，对非标准化文档进行配置和训练，提供海量训练数据快速生成、自动化模型生成及完整的模型训练和测试等功能，无纸化办公更简单',
      companyName: '—— 长沙银行股份有限公司',
    },
  ],
};
