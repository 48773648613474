import type { NextPage } from 'next';
import { Space, Image, Col, Row } from 'antd';
import Link from '@/components/Link';
import styles from './index.module.less';
import React from 'react';
import { track } from '@/utils/track';

export interface CardInfoProps {
  title: string;
  content: string[][];
  moreLink: { name: string; href: string; target?: '_self' | '_blank' };
  useLink?: { name: string; href: string; target?: '_self' | '_blank' };
  image?: string;
  des?: string;
  dot?: boolean;
}

const CardInfo: NextPage<CardInfoProps> = ({ des, title, content, moreLink, image, dot }) => {
  return (
    <div
      className={styles.cardInfo}
      onClick={() => {
        if (moreLink?.href) {
          window.open(moreLink?.href, moreLink.target || '_blank');
          track({ name: '按钮点击', keyword: `首页卡片【${title}】` });
        }
      }}
    >
      <p className={styles.subTitle}>{des}</p>
      <div className={styles.cover}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.cardContent}>
        <h2 className={styles.title}>{title}</h2>
        {content.map((row, i) => {
          // if (i > 1) {
          //   return '';
          // }
          return (
            <Row key={i.toString()} gutter={[8, 0]} className={styles.desc}>
              {row.map((val, k) => {
                return (
                  <Col key={k.toString()}>
                    <div className={styles.tagStyle}>{val}</div>
                  </Col>
                );
              })}
              {dot && i === content?.length - 1 && (
                <Col>
                  <Image
                    preview={false}
                    src={'/images/home/Edit_dots@2x.png'}
                    alt=""
                    width={16}
                    height={16}
                    style={{
                      marginTop: 13,
                    }}
                  />
                </Col>
              )}
            </Row>
          );
        })}
        <div className={styles.content}>
          <Space className={styles.linkWrapper} direction="vertical" size={16}>
            <Link href={moreLink.href}>
              <span>
                详情
                <Image
                  preview={false}
                  src={'/images/home/icon-right.svg'}
                  alt=""
                  width={20}
                  height={20}
                  style={{
                    marginTop: 5,
                    marginLeft: 4,
                  }}
                />
              </span>
            </Link>
          </Space>
        </div>
      </div>
      <div className={styles.lineHide} />
    </div>
  );
};

export default CardInfo;
