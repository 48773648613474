import { useMemo } from 'react';
import { Space } from 'antd';
import cx from 'classnames';
import Image from 'next/image';
import type { FC } from 'react';
import styles from './index.module.less';
import { officialTel } from '@/utils/static';

type theme = 'light';

interface IProps {
  bgSrc?: string;
  titleImg?: { src: string; width: number; height: number };
  theme?: theme;
}
/**
 *
 * @name 注册引导
 */
const SignUpGuide: FC<IProps> = ({ children, bgSrc, titleImg, theme }) => {
  const imageSrc = useMemo(() => {
    if (theme === 'light') {
      return {
        bg: bgSrc || '/images/home/guide_bg_light@2x.png',
        title: '/images/home/guide_title_light.svg',
        phone: '/images/home/guide_phone_light.svg',
      };
    }
    return {
      bg: bgSrc || '/images/home/guide_bg@2x.png',
      title: '/images/home/guide_title.svg',
      phone: '/images/home/guide_phone.svg',
    };
  }, [bgSrc, theme]);

  const title = titleImg || { src: imageSrc.title, width: 365, height: 25 };

  return (
    <div className={cx(styles.guideWrapper, { [styles.themeLight]: theme === 'light' })}>
      <div className={styles.bgWrapper}>
        <div className={styles.bg}>
          <Image unoptimized alt="background" src={imageSrc.bg} layout="fill" />
        </div>
      </div>
      <div className={cx('home-page', styles.content)}>
        <Image
          unoptimized
          src={title.src}
          width={title.width}
          height={title.height}
          alt="background"
        />
        <div className={styles.phoneWrapper}>
          <Image src={imageSrc.phone} width={20} height={20} alt="" layout="fixed" />
          <span className={styles.phone}>{officialTel}</span>
        </div>
        <Space size={16}>{children}</Space>
      </div>
    </div>
  );
};

export default SignUpGuide;
