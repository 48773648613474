import { useCallback, useState } from 'react';
import { createContainer } from 'unstated-next';

export const INITIAL_VALUE = 14814875596;

function useStore(initialCount: number = INITIAL_VALUE) {
  const [count, setCount] = useState(initialCount);

  const handleUpdateCount = useCallback(
    (count) => {
      setCount(count);
    },
    [count],
  );
  return {
    count,
    updateCount: handleUpdateCount,
  };
}

export const homeContainer = createContainer(useStore);
