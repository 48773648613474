import cx from 'classnames';
import { Col, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';
// import Card from './components/ProductCard';
import LeftCard from './components/ProductCard/CardInfo';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { ProductDataSource } from './dataSource';
import styles from './styles/product.module.less';
import Link from '@/components/Link';
/**
 * @name 产品介绍
 */
function Product() {
  return (
    <div className={cx('home-page-wrapper', styles['product-wrapper'])}>
      <div className="home-page">
        <div className={cx('title-wrapper', styles.titleWrapper)}>
          <h1>{ProductDataSource.titleWrapper.title}</h1>
          <Link href={ProductDataSource.titleWrapper.href} passHref>
            <span className={styles.link}> 查看更多产品</span>
            <RightOutlined className={styles.linkIcon} />
          </Link>
        </div>
        <OverPack playScale={0.1} always={false}>
          <QueueAnim type="bottom" key="queue" leaveReverse className={styles.contentWrapper}>
            <Row gutter={8} justify="center">
              {ProductDataSource.cards.map((info) => (
                <Col key={info.title} span={6}>
                  <LeftCard {...info} />
                </Col>
              ))}
            </Row>
            {/* <div key="cardList">
              <Space size={20} style={{ width: 800 }} align="start" wrap>
                {ProductDataSource.cards.map((info, i) => (
                  <Card key={i.toString()} {...info} />
                ))}
              </Space>
            </div> */}
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  );
}
export default Product;
