import Link from '@/components/Link';
import { Carousel, Space } from 'antd';
import type { NextPage } from 'next';
import Image from 'next/image';
import QueueAnim from 'rc-queue-anim';
import { useRef, useState } from 'react';

import styles from './index.module.less';

interface IProps {
  icon: any;
  title: string;
  desc: string;
  bgSrc: string | string[];
  useLink: string | (() => void);
  useText?: string;
  moreLink: string;
  sourceHref?: string;
  activated: boolean;
}
const CarouselInfo: NextPage<IProps> = ({
  icon,
  title,
  desc,
  activated,
  useLink,
  useText,
  sourceHref,
  bgSrc,
  moreLink,
}) => {
  const videoRef = useRef<any>();
  const [videoCanPlay, setVideoCanPlay] = useState(false);

  function onPalyOrReplay() {
    if (videoRef.current && activated) {
      setVideoCanPlay(true);
      const { currentTime, paused, readyState, ended } = videoRef.current;
      const playing = !!(currentTime > 0 && !paused && !ended && readyState > 2);
      if (playing) {
        // videoRef.current.currentTime = 0;
      } else {
        videoRef.current.play();
      }
    }
  }
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const imgList = Array.isArray(bgSrc) ? bgSrc : [bgSrc];

  return (
    <QueueAnim type="bottom" className={styles.wrapper}>
      <div key="leftContent" className={styles.leftContent}>
        <div className={styles.content}>
          <Image src={icon} alt="" width={60} height={60} layout="fixed" />
          <h2 className={styles.title}>{title}</h2>
          <p>{desc}</p>
        </div>
        <Space size={[16, 0]}>
          {useLink && (
            <Link
              href={typeof useLink === 'string' ? useLink : undefined}
              onClick={typeof useLink === 'function' ? useLink : undefined}
              isNative
              className={styles.useLink}
            >
              {useText || '申请试用'}
            </Link>
          )}
          <Link href={moreLink} isNative className={styles.moreLink}>
            了解更多
          </Link>
        </Space>
      </div>
      <div key="img" className={styles.imgWrapper}>
        {!videoCanPlay && (
          <Carousel {...settings}>
            {imgList.map((src) => (
              <div key={src}>
                <img src={src} alt={title} width={754} />
              </div>
            ))}
          </Carousel>
        )}
        {activated && sourceHref && (
          <video
            muted
            loop
            playsInline
            onCanPlay={onPalyOrReplay}
            onError={() => {
              setVideoCanPlay(false);
            }}
            autoPlay
            ref={videoRef}
          >
            <source src={sourceHref} type="video/mp4" />
          </video>
        )}
      </div>
    </QueueAnim>
  );
};

export default CarouselInfo;
