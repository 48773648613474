import { getIntFinQSiteUrl, getToken } from '@/utils';

export const toIntFinQ = () => {
  const url = getIntFinQSiteUrl();
  if (getToken()) {
    window.open(url);
  } else {
    window.open(`/user/login?redirect=${encodeURIComponent(url)}`);
  }
};

export const BannerData = {
  title: ['IntFinQ', '财经知识库问答'],
  desc: '一键检索上市公司公告和研报文档关键信息，支持跨文档对比分析，自定义创建和管理个人知识库，提升信息采集和处理效率',
  backgroundImage: '/images/product/knowledge_base/product-pic_banner_scene@2x.png',
  btnText: '在线试用',
};

export const AdvanceData = {
  advanceList: [
    {
      icon: '/images/product/knowledge_base/icon_finance_adv_01@2x.png',
      keyword: '快',
      title: '极速问答交互体验',
      desc: '百页级文档问答平均3秒内响应，自定义文档上传百页4秒内解析完成',
    },
    {
      icon: '/images/product/knowledge_base/icon_finance_adv_02@2x.png',
      keyword: '准',
      title: '信息检索定位精准，可溯源',
      desc: '针对财报、招股书、公司公告类金融文档，单文档及跨文档检索准确率均达到行业领先，精准定位原文出处，降低信息验证成本',
    },
    {
      icon: '/images/product/knowledge_base/icon_finance_adv_03@2x.png',
      keyword: '全',
      title: '完备财经知识库',
      desc: '覆盖上市公司历年财报、各类公告，历史招股书，行业、市场研报等金融知识数据',
    },
  ],
};

export const PlatformSceneData = {
  title: '产品特色功能',
  sceneList: [
    {
      name: '金融长文档阅读',
      desc: '对话式阅读体验，所问即所得，除检索结果之外提供精准原文段落，快速验证答案真实性',
      image: '/images/product/knowledge_base/feature01@2x.png',
    },
    {
      name: '跨文档信息检索',
      desc: '支持同行业多公司横向对比、多文档纵向时序发展分析，一问一答中化信息为洞察',
      image: '/images/product/knowledge_base/feature02@2x.png',
    },
    {
      name: '个人知识库管理',
      desc: '可任意上传1000份 500M以内的个人文档，支持PDF、图片等格式，运用行业领先问答检索能力，提供高效阅读体验',
      image: '/images/product/knowledge_base/feature03@2x.png',
    },
  ],
};

export const SceneData = {
  title: '应用场景',
  sceneList: [
    {
      image: '/images/product/knowledge_base/pic_customer_bg_01@2x.png',
      name: '金融投研人员',
      desc: '不管是一级还是二级市场投研人员，工作日常离不开案头研究，包括阅读历史招股书、分析上市公司历年财报、跟踪上市公司公告等，通过一键信息查询及智能文档阅读工具，极速获取一手关键信息，帮您专注价值决策。',
      cards: [
        {
          name: '一级投资研究员',
          desc: ['行业发展及企业竞争格局分析', '历史招股书及监管问询函案例查询'],
          type: '应用案例',
        },
        {
          name: '二级市场研究员',
          desc: ['上市公司基本面研究，公司动态追踪', '研报分析逻辑及数据查询'],
          type: '应用案例',
        },
      ],
    },
    {
      image: '/images/product/knowledge_base/pic_customer_bg_02@2x.png',
      name: '行业分析师',
      desc: '是否还在花很长时间查找、阅读行业调研报告、竞争对手分析、历史参考案例？我们支持快速准确的内容查询和总结，提高报告撰写的效率，确保报告内容的准确性，成为分析师的有力助手。',
      cards: [
        {
          name: '咨询/评估机构',
          desc: ['基于特定专题，通过公开信息查询、实地调研，形成深度分析报告'],
          type: '应用案例',
        },
        {
          name: '上市公司战略投资',
          desc: ['分析公司所处竞争格局，制定公司长期发展目标和战略方向'],
          type: '应用案例',
        },
      ],
    },
    {
      image: '/images/product/knowledge_base/pic_customer_bg_03@2x.png',
      name: '财经媒体从业者',
      desc: '对于市场关注热点、上市公司动态、个股专题研究等主题的内容生产者，日常花很长时间调研内容、筛选内容，我们提供完备金融知识库、通过检索生成内容，让素材收集不再成为难题。',
      cards: [
        {
          name: '财经自媒体',
          desc: ['热点内容发现，高质量内容素材收集、分析'],
          type: '应用案例',
        },
        {
          name: '垂媒记者',
          desc: ['跟踪行业的日常活动、动态，专题报道信息搜集'],
          type: '应用案例',
        },
      ],
    },
    {
      image: '/images/product/knowledge_base/pic_customer_bg_04@2x.png',
      name: '个人投资者',
      desc: '热点的股票基本面如何？看中的股票是否不靠谱、面临暴雷的风险？我们借助公司知识库及跨文档对比能力，帮您分析公司历年的经营业绩表现，为您的投资决策加一重保障。',
      cards: [
        {
          name: '个人投资者',
          desc: ['投资标的基本面分析、规避暴雷风险', '行业发展热点跟踪，发掘投资机会'],
          type: '应用案例',
        },
      ],
    },
  ],
};
