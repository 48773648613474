import cx from 'classnames';
import Image from 'next/image';
import { Carousel } from 'antd';
import MouseScroll from './components/NumberInfo';
import { BannerDataSource } from './dataSource';
import CheckBg from './assets/banner_check.svg';
import styles from './styles/banner.module.less';
import BannerTitle from '/public/images/home/pic_banner_slogan@2x.png';
import TextinTitle from '/public/images/home/home-white_textin.png';
import ArrowRightOutlined from '/public/images/home/ArrowRightOutlined@2x.png';
import { useInViewport } from 'ahooks';
import { useRef } from 'react';
import useWinTop from '@/utils/hooks/useWinTop';
import Link from '@/components/Link';
import { track } from '@/utils/track';

const Banner1 = () => (
  <div className={styles['home-banner-container']}>
    <div className={styles['home-banner-bg']}>
      <Image
        src={'/images/home/banner@2x.png'}
        alt={BannerDataSource.content.title}
        width={1200}
        height={600}
        layout="fixed"
      />
    </div>
    <div className={styles.videoWrapper}>
      <video autoPlay muted loop playsInline>
        <source src="//static.textin.com/videos/web/background.mp4" type="video/mp4" />
      </video>
    </div>
    <div className={cx('home-page', styles['home-banner-content'])}>
      <div className={styles['title-wrapper']}>
        <div className={styles['title-warpper-image']}>
          <Image src={BannerTitle} height={26} width={221} alt="" />
          <div className={styles['title-warpper-textin-image']}>
            <Image src={TextinTitle} height={40} width={163} alt="" />
          </div>
          <p className={styles['title-warpper-text']}>智能文字识别产品</p>
          <p className={styles['title-warpper-content']}>
            为全球用户提供智能图像处理、文字表格识别、文档内容提取产品
          </p>
          <Link
            href={'/product/list'}
            onClick={() => track({ name: '按钮点击', keyword: '首页banner1【查看产品】' })}
          >
            <div className={styles['title-warpper-button']}>
              <span className={styles['title-warpper-button-text']}>查看产品</span>
              <Image src={ArrowRightOutlined} height={20} width={20} alt="" />
            </div>
          </Link>
        </div>

        {BannerDataSource.content.children.map((desc, i) => {
          return (
            <div key={i.toString()} className={styles['desc-wrapper']}>
              <CheckBg />
              <div className={styles.desc}>{desc}</div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Banner2 = () => (
  <div className={cx(styles['home-banner-container'], styles['banner-2'])}>
    <div className={cx('home-page', styles['home-banner-content'])}>
      <div className={styles['title-wrapper']}>
        <div className={styles['title-warpper-image']}>
          <div className={styles['title-warpper-textin-image']}>
            <Image src={TextinTitle} height={40} width={163} alt="" />
          </div>
          <p className={styles['title-warpper-desc']}>
            <p>通用文档解析</p>
            <p>
              <span>100页</span>PDF仅需<span>2s</span>
            </p>
            <p>大模型公司的一致选择</p>
          </p>
          <div className={styles['banner-btn']}>
            <span style={{ marginRight: 30 }}>正在火热内测中</span>
            <Link
              href={'/experience/pdf_to_markdown'}
              onClick={() =>
                track({ name: '按钮点击', keyword: '首页banner通用文档解析【立即体验】' })
              }
            >
              <div className={styles['title-warpper-button']}>
                <span className={styles['title-warpper-button-text']}>立即体验</span>
                <Image src={ArrowRightOutlined} height={20} width={20} alt="" />
              </div>
            </Link>
          </div>
        </div>

        <div className={styles['banner-img']}>
          <img
            src="https://web-api.textin.com/open/image/download?filename=ec8f6f0a56f84e6396f3c1475d6596f9"
            width={342}
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
);

function Banner() {
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const inViewPort = useInViewport(bannerRef);
  const top = useWinTop(60);
  const isScroll = top > 60;

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };

  return (
    <div
      className={styles['home-banner']}
      ref={bannerRef}
      style={{ visibility: inViewPort ? 'visible' : 'hidden' }}
    >
      <Carousel {...settings}>
        <Banner1 />
        <Banner2 />
      </Carousel>

      <MouseScroll />

      <div
        className={cx(styles.bannerMask)}
        style={{ backgroundColor: isScroll ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)' }}
      />
    </div>
  );
}
export default Banner;
