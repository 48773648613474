import { useRef } from 'react';
import type { CarouselProps, CarouselRef } from 'antd/lib/carousel';

export default function useCarouselChange(props?: CarouselProps) {
  const startAnimate: any = useRef();
  const nextIndex = useRef<number>();
  const carouselRef = useRef<CarouselRef>(null);

  const goTo = (index) => {
    const curTime = new Date().getTime();
    if (startAnimate.current && curTime - startAnimate.current < 600) {
      nextIndex.current = index;
    } else {
      carouselRef.current?.goTo(index);
    }
  };

  const beforeChange = (currentSlide: number, nextSlide: number) => {
    startAnimate.current = new Date().getTime();
    if (props?.beforeChange) {
      props.beforeChange(currentSlide, nextSlide);
    }
  };

  const afterChange = (current: number) => {
    const index = nextIndex.current;
    if (index !== undefined && index !== current) {
      setTimeout(() => {
        carouselRef.current?.goTo(index);
      }, 16);
    } else {
      startAnimate.current = false;
    }
    nextIndex.current = undefined;
    if (props?.afterChange) {
      props.afterChange(current);
    }
  };

  return { goTo, beforeChange, afterChange, carouselRef };
}
