import Home from '@/containers/Home';
import type { NextPage } from 'next';
import { homeContainer } from '@/containers/Home/store';
import wrapperLayout from '@/components/Layout';
import type { FC } from 'react';

type MainPageType = NextPage<{ count: number }> & {
  Layout: FC<any>;
};

const MainPage: MainPageType = ({ count }) => {
  return (
    <homeContainer.Provider initialState={count}>
      <>
        <Home />
      </>
    </homeContainer.Provider>
  );
};

MainPage.Layout = wrapperLayout({
  headerLayoutProps: { theme: 'dark' },
});
export default MainPage;
