import { useSize } from 'ahooks';
import cx from 'classnames';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import PlanCard from './components/PlanCard';
import { PlanDataSource } from './dataSource';
import styles from './styles/plan.module.less';
import { useCallback, useEffect, useState } from 'react';

/**
 *
 * @name 技术方案
 */
const pageX = 1180;
const cardX = 295;

const Plan = () => {
  const [target, setTarget] = useState<HTMLDivElement | null>(null);
  const [activeIdx, setActiveIdx] = useState(0);
  useEffect(() => {
    setTarget(document.querySelector('#__next') as HTMLDivElement);
  }, []);

  const handleActiveChange = useCallback(
    (idx) => {
      setActiveIdx(idx);
    },
    [activeIdx],
  );

  const size = useSize(target);

  const leftX = size.width ? (size.width - pageX + cardX) / 2 : 200;
  const { titleWrapper, blocks } = PlanDataSource;
  return (
    <div
      className={cx('home-page-wrapper', styles.planWrapper)}
      style={{ backgroundPositionX: `${leftX}px` }}
    >
      <div style={{ width: leftX }} className={styles.box} />
      <div style={{ left: leftX }} className={styles.bg} />
      <div className="home-page">
        <div className={'title-wrapper'}>
          <h1>{titleWrapper.title}</h1>
          <p>{titleWrapper.content}</p>
        </div>
        <OverPack playScale={0.1} always={false}>
          <QueueAnim
            type="alpha"
            key="queue"
            component="div"
            componentProps={{ className: styles.content }}
          >
            {blocks.map((block) => (
              <div key={block.id}>
                <PlanCard
                  {...block}
                  isActive={block.id === activeIdx}
                  onActive={handleActiveChange}
                />
              </div>
            ))}
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  );
};

export default Plan;
