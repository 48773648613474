import cx from 'classnames';
import { SceneDataSource } from './dataSource';
import { useMemo, useState } from 'react';
import CarouselInfo from './components/CarouselInfo';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import styles from './styles/scene.module.less';
import Tabs from '@/components/common/Tabs';

/**
 *
 * @name 应用
 */
function Scene() {
  const { carousel, titleWrapper } = SceneDataSource;
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const generateTabList = useMemo(() => {
    return carousel.map((item, i) => {
      return {
        tab: item.title,
        key: i.toString(),
        tabPane: (
          <CarouselInfo key={i.toString()} activated={activeSlide === i} {...item.children} />
        ),
      };
    });
  }, [carousel, activeSlide]);

  return (
    <div className={cx('home-page-wrapper', styles.sceneWrapper)}>
      <div className="home-page">
        <div className={'title-wrapper'}>
          <h1>{titleWrapper.title}</h1>
        </div>
      </div>
      <OverPack playScale={0.1} always={false}>
        <div className={cx('home-page-wrapper', styles.lineWrapper)}>
          <Tabs
            tabList={generateTabList}
            className="home-page"
            afterChange={(current) => {
              setActiveSlide(current);
            }}
          />
        </div>
      </OverPack>
    </div>
  );
}
export default Scene;
