import Link from '@/components/Link';
import { useHover } from 'ahooks';
import classNames from 'classnames';
import type { NextPage } from 'next';
import Image from 'next/image';
import { useRef } from 'react';

import styles from './index.module.less';

interface IProps {
  id: number;
  title: string;
  content: string;
  imgHref: string;
  useLink: { href: string; name: string; target?: '_self' | '_blank' };
  isActive: boolean;
  onActive: (id: number) => void;
}

const defaultUrl = '/images/home/method_pic_default@2x.jpg';

const PlanCard: NextPage<IProps> = ({
  title,
  id,
  content,
  useLink,
  imgHref,
  onActive,
  isActive,
}) => {
  const ref = useRef<any>();
  useHover(ref, {
    onEnter() {
      onActive(id);
    },
  });

  return (
    <div
      className={classNames(styles.wrapper, { [styles.wrapActive]: isActive })}
      ref={ref}
      style={{ backgroundImage: `url(${defaultUrl})` }}
      onClick={() => {
        if (useLink.href) {
          window.open(useLink.href, useLink.target || '_blank');
        }
      }}
    >
      <div className={styles.content}>
        <h2>{title}</h2>
        <p>{content}</p>
        <div className={styles.linkWrapper}>
          <Link href={useLink.href} target={useLink.target} className={styles.link} isNative>
            {useLink.name}
          </Link>
          <Image
            width={40}
            height={40}
            className={styles.arrow}
            src="/images/home/icon_circle_arrow.svg"
            alt=""
          />
        </div>
      </div>
      <div className={styles.bg} style={{ backgroundImage: `url(${imgHref})` }} />
    </div>
  );
};

export default PlanCard;
