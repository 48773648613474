import { Carousel, Row, Col } from 'antd';
import type { CarouselProps } from 'antd';
import type { FC, Key, ReactElement } from 'react';
import { useMemo } from 'react';
import { useState, useEffect, useRef } from 'react';
import useCarouselChange from '@/components/common/hooks/useCarouselChange';
import styles from './index.module.scss';
import classNames from 'classnames';
interface TabListConfig {
  tab: string;
  key: Key;
  tabPane: ReactElement;
}
interface TabsProps {
  tabList: TabListConfig[];
  className?: string;
}
const Tabs: FC<TabsProps & Omit<CarouselProps, 'className'>> = ({
  tabList = [],
  className,
  ...props
}) => {
  const [curActivePane, setActiveTab] = useState(0);
  const [distance, setDistance] = useState(0);

  const inkBar = useRef<HTMLDivElement>(null);

  const { carouselRef, goTo, ...carouselProps } = useCarouselChange(props);

  useEffect(() => {
    const inkBarNode = inkBar.current;
    if (!inkBarNode || !tabList.length) return;
    const parentNode = inkBarNode.parentNode as HTMLElement;
    const parentNodeWidth = parentNode && parentNode.getBoundingClientRect().width;
    const inkBarWidth = parentNodeWidth / tabList.length;
    inkBarNode.style.width = `${inkBarWidth}px`;
    setDistance(inkBarWidth);
  }, [tabList]);
  const generateChildren = useMemo(() => {
    return tabList.map((tableItem) => (
      <div key={tableItem.key} className={styles['tab-content']}>
        {tableItem.tabPane}
      </div>
    ));
  }, [tabList]);
  return (
    <div style={{ width: '100%' }}>
      <div className={styles['tab-bar-wrapper']}>
        <div className={styles['left-line']} />
        <div style={{ position: 'relative', width: 1180 }}>
          <Row className={styles['tab-pane-header']}>
            {tabList.map((listItem, idx) => (
              <Col
                className={classNames(styles['tab-pane'], {
                  [styles['tab-pane-active']]: curActivePane === idx,
                })}
                key={listItem.key}
              >
                <span
                  style={{ padding: '0 20px' }}
                  onMouseEnter={() => {
                    if (curActivePane === idx) return;
                    setActiveTab(idx);
                    goTo(idx);
                    inkBar.current!.style.transform = `translateX(${distance * idx}px)`;
                  }}
                >
                  {listItem.tab}
                </span>
              </Col>
            ))}
          </Row>
          <div ref={inkBar} className={styles['active-ink-bar']} />
        </div>
        <div className={styles['right-line']} />
      </div>
      <div className={className}>
        <Carousel ref={carouselRef} effect="fade" dots={false} infinite={false} {...carouselProps}>
          {generateChildren}
        </Carousel>
      </div>
    </div>
  );
};
export default Tabs;
