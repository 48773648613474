import type { NextPage } from 'next';
import Image from 'next/image';

interface IProps {
  imageSrc: string;
}

const Logo: NextPage<IProps> = ({ imageSrc }) => {
  return (
    <div style={{ width: 166, height: 68, position: 'relative' }}>
      <Image src={imageSrc} alt="" layout="fill" />
    </div>
  );
};

export default Logo;
