export const financialBannerData = {
  title: 'TextIn 财报机器人',
  desc: '智能分类、识别财务报表（资产负债表、利润表、现金流量表），进行科目匹配与试算平衡校对，预览/存储/导出原始数据、标准科目数据、配置数据，可与客户系统深度集成',
  backgroundImage: '/images/product/banner_finance.jpg',
  btnText: '申请试用',
};

export const financialVideoData = {
  title: '产品介绍',
  source: '//static.textin.com/videos/web/product/Textin_finance_robot.mp4',
};

export const financialPlatformSceneData = {
  title: '产品功能',
  sceneList: [
    {
      name: '智能化财务报表识别',
      desc: '支持上传PDF、图片、Excel格式财务报表，可同时识别资产负债表、利润表、现金流量表，并智能判断报表类型，智能判断科目列、数值列',
      image: '/images/financial/financial_report.png',
    },
    {
      name: '全局识别结果数据管控',
      desc: '智能判断报表类型，科目列、数值列智能识别面板可编辑财务报表数据、调整科目行、忽略配平、一键删除空行、预览当前财报数据等',
      image: '/images/financial/financial_global.png',
    },
    {
      name: '智能化科目匹配',
      desc: '财务报表科目与标准模版科目进行置信度匹配，增加科目相似名，提高科目匹配准确度',
      image: '/images/financial/financial_intelligent.png',
    },
    {
      name: '试算平衡校验及配平',
      desc: '根据财务准则中配置的勾稽关系公式进行财务数据校验，精准定位错误数值，减少数据错误风险',
      image: '/images/financial/financial_balance.png',
    },
    {
      name: '财报数据处理提效',
      desc: '可预览、存储、导出三份财报数据：原始OCR识别数据、标准科目数据、配置操作数据',
      image: '/images/financial/financial_improve.png',
    },
    {
      name: '财务准则灵活配置',
      desc: '系统内置多个行业新旧标准会计准则模版，用户可根据企业财报需求，自定义会计准则模版，自定义标准科目及勾稽关系公式',
      image: '/images/financial/financial_setting.png',
    },
  ],
};

export const financialAdvanceData = {
  title: '产品优势',
  backgroundImage: '/images/scene/pic_adv_bg.png',
  advanceList: [
    {
      icon: '/images/contract/icon_contract_adv_01.png',
      name: '高精度报表识别',
      desc: '基于自研的表格识别引擎，针对少表格线、无表格线的复杂报表类型，支持高精准度识别解析',
    },
    {
      icon: '/images/contract/icon_contract_adv_02.png',
      name: '核心技术能力',
      desc: '基于自研的智能文字识别、表格识别、自然语言处理技术、印章检测识别技术，打造全智能AI财报管理平台',
    },
    {
      icon: '/images/contract/icon_contract_adv_03.png',
      name: '多种集成方式',
      desc: '支持公有云服务、私有化部署、API集成多种集成方式，与企业内部管理系统无缝对接，赋能企业降本增效的同时，保护企业数据隐私',
    },
    {
      icon: '/images/contract/icon_contract_adv_04.png',
      name: '全行业适用',
      desc: '适用于银行、证券、信托、融资租赁、供应链金融等多种行业，致力于优化企业财务报表采集效率，提高企业运营效率',
    },
  ],
};

export const financialSceneData = {
  title: '应用场景',
  sceneList: [
    {
      image: '/images/financial/pic_finance_scene_01@2x.png',
      name: '银行信贷业务',
      desc: '银行信贷业务中贷前调查需对借款方的财务状况进行分析，需收集企业近年的财务报表，对企业资产负债、资金结构、资金周转、盈利能力、现金流量等财务状况分析',
    },
    {
      image: '/images/financial/pic_finance_scene_02@2x.png',
      name: '融资租赁风险管理',
      desc: '通过企业近几年的财务报表和相关明细表分析经营情况，租后定期进行租后管理检查，了解承租人与担保人的财务情况，是否有财务恶化现象，确保合同履约能力，便于融资租赁企业进行尽调分析',
    },
    {
      image: '/images/financial/pic_finance_scene_03@2x.png',
      name: '供应链金融',
      desc: '融资企业的生产经营状况直接关系着整个供应链的正常运行，商业银行在授信审核时重点考察融资企业的资信状况，对企业财务状况分析',
    },
    {
      image: '/images/financial/pic_finance_scene_04@2x.png',
      name: '不良贷款审查',
      desc: '银行通过企业近几年财务报表进行偿债能力分析，判断借款企业的偿债能力是否能覆盖已有贷款和待审批贷款',
    },
    {
      image: '/images/financial/pic_finance_scene_05@2x.png',
      name: '企业关联方财务分析',
      desc: '搭建企业知识图谱，供应方准入财务状况分析。采集企业财务报表数据进行盈利能力分析、成长性分析、现金流量指标分析、偿债能力分析、经营效率分析、经营协调性分析',
    },
    {
      image: '/images/financial/pic_finance_scene_06@2x.png',
      name: '投资管理',
      desc: '投资管理风险控制，投资决策前需对企业进行大量业务和财务的分析调研，投后管理时需对被投企业的财务状况进行全面分析',
    },
  ],
};

export const financialPartnerList = {
  image: '/images/financial/pic_customer_financial_bg@2x.png',
  partnerList: [
    {
      logo: '/images/financial/scene_logo_spd-bank_01@2x.png',
      companyInfo:
        '在授信业务中，需要收集客户近年财务报表，行方引入合合信息 TextIn财报机器人，减轻人工录入财报数据的工作，提升效率与准确率，优化业务流程。',
      companyName: '—— 南海农商行',
    },
    {
      logo: '/images/financial/scene_logo_spd-bank_02@2x.png',
      companyInfo:
        '复星集团进行投资决策前需对企业进行大量业务和财务的分析调研，在投后管理时需对被投企业的财务状况进行全面分析，人工审核众多企业财报耗费大量时间和人力成本。TextIn财报机器人助力复星集团快速识别标的企业财报信息，大大减少报表处理时间，同时自动规范数据出口，提升投资管理的效率。',
      companyName: '—— 复星集团',
    },
    {
      logo: '/images/financial/scene_logo_spd-bank_03@2x.png',
      companyInfo:
        '传统的人工审阅录入效率低且容易出错，大幅降低了信贷审批速度。引入合合信息 TextIn财报机器人，协助人工进行财报录入，显著提升业务智能化水平和客户体验。',
      companyName: '—— 中国进出口银行',
    },
  ],
};
