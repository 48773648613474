import { useState } from 'react';
import cx from 'classnames';
import Logo from './components/Logo';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { CustomerDataSource } from './dataSource';

import styles from './styles/customer.module.less';

/**
 * @name 我的客户
 */
const Customer = () => {
  const { titleWrapper, logos } = CustomerDataSource;
  const [currentLogos] = useState(logos.map((logo) => logo.active));
  return (
    <div className={cx('home-page-wrapper', styles.customerWrapper)}>
      <div className="home-page">
        <div className={'title-wrapper'}>
          <h1>{titleWrapper.title}</h1>
          <p>{titleWrapper.content}</p>
        </div>
        <OverPack playScale={0.1} always={false}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'space-between',
            }}
          >
            {currentLogos.map((logo, ii) => (
              <div key={ii.toString()} className={styles.imgWrapper}>
                <Logo imageSrc={logo} />
              </div>
            ))}
          </div>
        </OverPack>
      </div>
    </div>
  );
};

export default Customer;
