import { Fragment } from 'react';
import Banner from './Banner';
import Product from './Product';
import Scene from './Scene';
import Plan from './Plan';
import Customer from './ Customer';
import SignUpGuide from '@/components/SignUpGuide';
import { dashboardSitePrefixPath, getToken } from '@/utils';
import Link from '@/components/Link';

const Home = () => {
  return (
    <Fragment>
      <Banner />
      <Product />
      <Scene />
      <Plan />
      <Customer />
      <SignUpGuide>
        <div
          onClick={(e) => {
            e.stopPropagation();
            const href = getToken()
              ? `${dashboardSitePrefixPath}/dashboard/service/public`
              : '/user/register';
            window.open(href);
          }}
          className={'useBtn'}
        >
          免费使用
        </div>
        <Link href="/contact" passHref>
          <div className={'contactBtn'}>联系我们</div>
        </Link>
      </SignUpGuide>
    </Fragment>
  );
};

export default Home;
